import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import PageLoaderComp from "../../component/pageLoaderComp";
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { alertActions,announceActions } from '../../_actions';
import Procedures from './FormComponent/Procedures';
import Express from './FormComponent/Express';
import PaymentSideBlock from './FormComponent/PaymentSideBlock';
import { Helmet } from 'react-helmet';
import Footer from '../../component/Footer';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY, { locale: "fr" });

const CheckoutForm = ({ orderData }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingFullpage, setLoadingFullpage] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    setLoading(true);
    setLoadingFullpage(true);
    try {
      const { error: submitError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${window.location.origin}/payment-success`,
          payment_method_data: {
            billing_details: {
              name: orderData.billing_address_json_parse.billing_address_nom || '',
              email: orderData?.billing_address_json_parse?.email || '',
            },
          },
        },
        redirect: "if_required"
      });

      if (submitError) {
        setError(submitError.message);
        dispatch(alertActions.error(submitError.message));
      } else if (paymentIntent && paymentIntent.status === "succeeded") {
        if (typeof orderData.id !== "string") {
          orderData.id = String(orderData.id);  // Convert to string if it's not
        }
        let data = {
          "announce_id":orderData.id,
          "payment_order_id":moment().format("DDMMY")+"_"+orderData.id.padStart(8, '0'),
          "invoice_number":moment().format("DDMMY")+"_"+orderData.id.padStart(8, '0'),
          "invoice_date":moment().format("DD-MM-Y"),
          "date_payment":moment().format("DD-MM-Y"),
          "payment_intent":paymentIntent.id,
          "payment_price":orderData.total,
          "payment_intent_client_secret":paymentIntent.client_secret,
        }
        let updateSuccess = await updateAnnouncePayment(data);
        dispatch(alertActions.success("Paiement réussi!"));
        navigate('/payment-success', { 
          state: { 
            orderData: {
              ...data,
              billing_address_json_parse: orderData.billing_address_json_parse
            }
          }
        });
      }
    } catch (e) {
      const errorMessage = "Une erreur s'est produite lors du traitement de votre paiement.";
      setError(errorMessage);
      dispatch(alertActions.error(errorMessage));
    } finally {
      setLoading(false);
      setLoadingFullpage(false);
    }
  };
  async function updateAnnouncePayment(bodyData){
    try {
        await dispatch(announceActions.updateSuccessPayment(bodyData)).then((responseData) => {       
       // console.log('responseData',responseData);
        return true; 
      })
      .catch((err) => {           
        return false;     
      });
     /* return {success:true,data:response.data} */ 
    } catch (error) {
      // // console.log(error); 
      return false;     
    }
  }
  // if(loadingFullpage){
  //   return <PageLoaderComp />;
  // }


  return (
    
    <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto p-6">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">Détails du paiement</h2>
        {orderData && (
          <div className="bg-gray-50 p-6 rounded-lg mb-6 border border-gray-200">
            <div className="flex justify-between items-center mb-4">
              <span className="text-gray-600">Montant HT:</span>
              <span className="font-semibold">{orderData.subtotal}€</span>
            </div>
            <div className="flex justify-between items-center mb-4">
              <span className="text-gray-600">TVA (20%):</span>
              <span className="font-semibold">{orderData.tax}€</span>
            </div>
            <div className="flex justify-between items-center pt-4 border-t border-gray-200">
              <span className="text-lg font-bold text-gray-800">Total TTC:</span>
              <span className="text-lg font-bold text-primary">{orderData.total}€</span>
            </div>
          </div>
        )}
      </div>
      
      <div className="mb-8">
        <PaymentElement options={{
          layout: {
            type: 'tabs',
            defaultCollapsed: false,
          },
        }} />
      </div>
      
      {error && (
        <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg">
          <p className="text-sm font-medium">{error}</p>
        </div>
      )}
      
      <button
        type="submit"
        disabled={!stripe || loading}
        className="w-full bg-primary text-white py-4 px-6 rounded-lg font-semibold hover:bg-secondary transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {loading ? (
          <span className="flex items-center justify-center">
            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
            Traitement en cours...
          </span>
        ) : (
          "Payer maintenant"
        )}
      </button>
      
      <div className="mt-6 text-center">
        <p className="text-sm text-gray-500">
          Le paiement est sécurisé par Stripe. Vos informations de carte sont protégées.
        </p>
      </div>
    </form>
  );
};

export default function CustomerPayemnt() {
  const [searchParams] = useSearchParams();
  const [orderData, setOrderData] = useState(null);
  const [error, setError] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  
  const orderId = searchParams.get("orderId");
  const token = searchParams.get("token");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (orderId && token) {
      fetchOrderDetails();
    } else {
      const errorMsg = "Lien de paiement invalide. Veuillez vérifier votre URL.";
      setError(errorMsg);
      dispatch(alertActions.error(errorMsg));
      setLoading(false);
    }
  }, [orderId, token]);
  async function fetchOrderDetails() {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/announce/announce/${orderId}`,
        {
          headers: {
            "Content-Type": "application/json"
          },
        }
      );
      console.log('response.sucess',response.data.data);

      if (response.data.sucess) {
        if (token !== response.data.data.token) {
          setError("Lien de paiement invalide. Veuillez vérifier votre URL.");
         // throw new Error("Invalid payment link. Please check your URL.");
        }
        if (response.data.data.status===3) {
          setError("Paiement déjà effectué.");
         // throw new Error("Invalid payment link. Please check your URL.");
        }
        setOrderData(response.data.data);
        // Initialize Stripe payment intent
        const intentResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}api/create-payment-intent`,
          {
            items: [],
            announce_id: orderId,
            price: response.data.data.total,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setClientSecret(intentResponse.data.clientSecret);
      } else {
        setError(response.data.message || "Impossible de récupérer les détails de la commande.");
      }
    } catch (err) {
      const errorMsg = err.response?.data?.message || "Une erreur s'est produite lors de la récupération des détails de la commande.";
      setError(errorMsg);
      dispatch(alertActions.error(errorMsg));
    } finally {
      setLoading(false);
    }
  }
//console.log('orderData-orderData',orderData.billing_address_json_parse.billing_address_nom);
  if (loading) {
    return <PageLoaderComp />;
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50 px-4">
        <div className="bg-white p-8 rounded-lg shadow-sm border border-red-100 max-w-md w-full">
          <div className="text-center">
            <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100 mb-4">
              <svg className="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-2">Erreur de paiement</h3>
            <p className="text-sm text-gray-500">{error}</p>
          </div>
        </div>
      </div>
    );
  }

  if (!clientSecret) {
    return null;
  }

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'system-ui, sans-serif',
        spacingUnit: '4px',
        borderRadius: '8px',
      },
    },
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4">
      <Helmet>
        <title>Liste des Journaux Habilités pour Annonces Légales | BusyPlace</title>
        <meta name="description" content="Trouvez facilement le journal officiel adapté à vos besoins pour la publication d'annonces légales avec BusyPlace. Découvrez notre annuaire des journaux habilités dans toute la France pour une publication rapide et conforme." />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/journaux-habilites`} /> */}
      </Helmet>
      <div className='md:max-w-[1477px] relative w-full h-full m-auto py-7 px-4 sm:px-14'>
        
        <div className='relative my-12'>
          <div className='grid grid-cols-12 gap-5'>
            <div className='mb-8 col-span-12 2lg:col-span-8'>
              <div className="max-w-4xl mx-auto">
                <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                  {/* <div className="border-b border-gray-200 px-6 py-4">
                    <h1 className="text-xl font-semibold text-gray-800">
                      Paiement sécurisé
                    </h1>
                  </div> */}
                  
                  <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm orderData={orderData} />
                  </Elements>
                </div>
              </div>
            </div>
            <div className=' col-span-12 2lg:col-span-4'>
              <Procedures />
              <Express />
              <PaymentSideBlock />
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}
