import React, { useState,useEffect } from 'react'
import { Link,useNavigate,useLocation,useParams } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux';
import { announceActions,alertActions } from '../../_actions';
import PageLoaderComp from '../../component/pageLoaderComp';
import axios from 'axios'

import moment from 'moment'

import { Checkbox, FormControlLabel, FormGroup,TextField } from '@mui/material'

import { TbPointFilled } from 'react-icons/tb'

export default function PaymentForm(props) {

  const alert = useSelector(state => state.alert);

  const dispatch = useDispatch(); 
  const [loadingFullpage, setLoadingFullpage] = useState(false);
  let navigate = useNavigate();

  let { ads,adsname } = useParams();

  const currentUser = useSelector(state => state.authentication.user);

  const announceData = useSelector(state => state.announce.announce);

  const [cartData,setCartData]=useState("");
  const [delegateEmail, setDelegateEmail] = useState("");
  

  useEffect(() => {
    window.scrollTo(0, 0)
    if(announceData){
      getCartDetails(announceData.announce_id);
    }
  }, [])
   console.log("cartData",cartData);
  const HandleDelegateEmail=async()=>{
    setLoadingFullpage(true);
    try 
    {
        setLoadingFullpage(true);
        let updatepaymentdata=await updatePayment();
        const formData = new FormData();
        formData.append('announce_id', announceData.announce_id);
        formData.append('user_id', currentUser.id);
        formData.append("delegate_email", delegateEmail);
        const response = await axios.put(`${process.env.REACT_APP_API_URL}api/announce/delegate_payment`,formData,{headers: {'Content-Type': 'application/json'}});
        console.log("response-deleresponse",response);
        if (response.data.sucess) 
        {
          dispatch(alertActions.success("Email envoyé avec succès"));
          navigate('/thank-you', { 
            state: { 
              email: delegateEmail
            }
          });
        }
      } catch (error) {
        console.error("Update failed:", error);
        const errorMessage = "Erreur lors de l'envoi de l'email";
        dispatch(alertActions.error(errorMessage));
      } 
    finally 
    {
        setLoadingFullpage(false); 
    }
  }
  const updatePayment=async()=>{
    
    delete cartData.data.billing_address_json;
    delete cartData.data.currentUserDetails;
    delete cartData.data.select_cartdetailJ;
    delete cartData.data.select_cartdetailN;
    delete cartData.data.select_department;
    delete cartData.data.votre_annonce_json;
    delete cartData.data.votre_annonce;
    //delete cartData.data.billing_address_json_parse;
    //delete cartData.data.user_detail;
    delete cartData.data.votre_html;
    delete cartData.data.journalempowered
   // console.log('cartData-cartData',cartData);
    try {
      const getResponse = await dispatch(announceActions.updatePayment({
        announce_id: announceData?.announce_id,
        user_id: currentUser?.data?.id || "",
        serenite: props.isCheckedSerenity,
        paymentData: cartData
      }));
      
      if (getResponse) 
      {
        setDelegateEmail('');
        return true;
      } 
    } catch (err) {
      setLoadingFullpage(false);
      return false;
    }
  }
    
  const getCartDetails=async(announce_id)=>{

    let user_id = "";

    if(currentUser && currentUser.data){

      user_id = currentUser.data.id;

    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/cart/${announce_id}?user_id=${user_id}`);
      if(response.data.sucess === true){
        setCartData(response.data);
        props.setPaymentDataFunc(response.data)
      }
    } catch (error) {

      // // console.log(error);       

    }
  }

  const handlecheckedDiscount=async(checkedVal,type)=>{

   

    let cardDataAnnounce = {...cartData};

    if(type ==="use_balance"){

      cardDataAnnounce.data.use_balance = checkedVal;

    }

    if(type==="use_discount"){

      cardDataAnnounce.data.use_discount = checkedVal;

      

    }

    let news_price_new=0;

    

    if((cardDataAnnounce.data.use_discount === false || cardDataAnnounce.data.use_discount === 0) && (cardDataAnnounce.data.use_balance === false  || cardDataAnnounce.data.use_balance === 0))

      {

        news_price_new=parseFloat(cardDataAnnounce.data.news_price);

      }

      else if((cardDataAnnounce.data.use_discount === true  || cardDataAnnounce.data.use_discount === 1) && (cardDataAnnounce.data.use_balance === false || cardDataAnnounce.data.use_balance === 0))

      {

          news_price_new=parseFloat(cardDataAnnounce.data.news_price)-parseFloat(cardDataAnnounce.data.discountAmount);

      }

      else

      {

          let balance_discountnew = parseFloat(cardDataAnnounce.data.discountAmount)+parseFloat(cardDataAnnounce.data.user_balance_discount);

          if(balance_discountnew >= parseFloat(cardDataAnnounce.data.news_price))

          {

              news_price_new=0;

          }

          else

          {

              news_price_new=parseFloat(cardDataAnnounce.data.news_price)-parseFloat(balance_discountnew);

          }
      }
      cardDataAnnounce.data.subtotal = parseFloat(news_price_new).toFixed(2);

      cardDataAnnounce.data.tax = parseFloat(news_price_new)*.20;

      cardDataAnnounce.data.total = (parseFloat(cardDataAnnounce.data.subtotal)+parseFloat(cardDataAnnounce.data.tax)).toFixed(2);
    setCartData(cardDataAnnounce);

    props.setPaymentDataFunc(cardDataAnnounce)

  }
  console.log("cartData-props",props);
  console.log('news_price_new',cartData);

  return (
    <> 
         {loadingFullpage?<PageLoaderComp />:""}
        <div className='py-6 px-3 sm:px-6' >

        <h1 className='text-lg sm:text-2xl font-black text-primary'>Récapitulatif de votre commande</h1>

        </div>

        <div className='my-4 mx-2 sm:mx-auto sm:my-12 max-w-[414px] m-auto'>

          <div className=' rounded-[14px] p-5 sm:mx-0 mx-2' style={{ 'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)' }} dangerouslySetInnerHTML={{__html:cartData&& cartData.data && cartData.data.votre_html? cartData.data.votre_html:""}}>

            {/* {cartData&& cartData.data && cartData.data.votre_html? cartData.data.votre_html:""} */}

          </div>

          {/* <div className=' rounded-[14px] p-5' style={{ 'boxShadow': '0px 4px 4px rgba(0, 0, 0, 0.25)' }}>

            <h6 className='text-base font-normal text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.type_acte==='ACTE_AUTHENTIQUE'? `Par Acte authentique du ${cartData.data.votre_annonce_json.date_acte} recu par me ${cartData.data.votre_annonce_json.nom_famille} officiant a ${cartData.data.votre_annonce_json.officiant_to} il a été constitué une ${cartData.data.votre_annonce_json.forme_juridique} dénommée`:`Par  Acte SSP  du ${cartData.data.votre_annonce_json.date_acte} il a été constitué une ${cartData.data.votre_annonce_json.forme_juridique} dénommée `:"" } :</h6>

            <h3 className='text-lg font-bold text-primary text-center my-4'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.enseigne_commerciale:""}</h3>



            <h5 className='text-base font-medium text-primary  '>Sigle &nbsp;: <span className='font-normal text-[15px] text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.sigle:""}</span></h5>



            <h5 className='text-base font-medium text-primary  '>Nom commercial&nbsp;: <span className='font-normal text-[15px] text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.enseigne_commerciale:""}</span></h5>



            <h5 className='text-base font-medium text-primary  '>Siège social&nbsp;: <span className='font-normal text-[15px] text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?`${cartData.data.votre_annonce_json.adresse} ${cartData.data.votre_annonce_json.adresse_suite} ${cartData.data.votre_annonce_json.code_postal} ${cartData.data.votre_annonce_json.ville} `:""}</span></h5>



            <h5 className='text-base font-medium text-primary  '>Capital&nbsp;: <span className='font-normal text-[15px] text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.capital:0} €</span></h5>



            <h5 className='text-base font-medium text-primary  '>Objet&nbsp;: <span className='font-normal text-[15px] text-gray'>{cartData&& cartData.data && cartData.data.votre_annonce_json?cartData.data.votre_annonce_json.objet:""}</span></h5>



            {cartData&& cartData.data && cartData.data.votre_annonce_json && ((cartData.data.votre_annonce_json.La_gerance).length>0)?  

              cartData.data.votre_annonce_json.La_gerance.map((val,key)=>{

                return <h5 className='text-base font-medium text-primary  '>Gérant&nbsp;: <span className='font-normal text-[15px] text-gray'> {val.type_personne_lagrance==='PERSONNE_PHYSIQUE'? `${val.civilite_lagrance} ${val.nomlagrance} ${val.prenomlagrance} ${val.adresselagrance} ${val.adresse_suitelagrance} ${val.code_postallagrance} ${val.villelagrance}`:val.type_personne_lagrance==='PERSONNE_MORALE'? ` ${val.denomination_lagrance} ${val.forme_juridiquelagrance} au capital de ${val.capital_lagrance} &euro; sise ${val.adresselagrance} ${val.adresse_suitelagrance} ${val.code_postallagrance} ${val.villelagrance} ${val.siren_lagrance} RCS ${val.greffelagrance} repr&#xE9;sent&#xE9;e par ${val.civilite_lagrance} ${val.nomlagrance} ${val.prenomlagrance}`:""}</span></h5>

              })          

             

            : ""}

            {cartData&& cartData.data && cartData.data.votre_annonce_json?

              <h5 className='text-base font-medium text-primary  '>Durée&nbsp;: <span className='font-normal text-[15px] text-gray'>

              {cartData.data.votre_annonce_json.duree} ans &#xE0; compter de l'immatriculation au RCS de {cartData.data.votre_annonce_json.greffe}</span></h5>

            :""}

          </div> */}





        </div>

        <div className='h-[1px] bg-gray-light my-2  px-3 sm:px-5 mx-5'></div>

        <div className='py-3  px-1 sm:px-4 flex items-start justify-between mb-1'>

          <div>

            <p className='text-base font-normal text-primary'>Annonce {cartData && cartData.data && cartData.data.select_cartdetailN?cartData.data.select_cartdetailN.newspaper_name:""}</p>

            {/* <p className='text-base font-normal text-primary'>Attestation immédiate </p> */}

            <p className='text-base font-normal text-primary'>Remise accordée: {cartData && cartData.data ?cartData.data.discountPer+"%":"0%"} </p>

            <p className='text-base font-normal text-primary'>Utiliser Votre Remise </p>

          </div>

          <div className='text-right'>

            <p className='text-base font-normal text-primary my-1'>{cartData && cartData.data ?cartData.data.news_price:0} €</p>

            <p className='text-base font-normal text-primary my-1'>-{cartData && cartData.data ?cartData.data.discountAmount:0} €</p>

            <p className='text-base font-normal text-primary my-1'>

              <Checkbox

                  checked={cartData && cartData.data ?cartData.data.use_discount:false}

                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}

                  onChange={(e)=>handlecheckedDiscount(e.target.checked,"use_discount")}

                />

            </p>



          </div>

        </div>

        <div className='my-0 sm:my-4 block mx-1 sm:mx-5'>

          <div className='bg-[#000aff21] py-3 sm:p-4 flex items-center sm:gap-3 rounded-lg'>

            <div>

              <Checkbox

                checked={props.isCheckedSerenity}

                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}

                onChange={(e)=>props.setIscheckedSerenity(e.target.checked)}

              />

            </div>

            <div className='sm:flex justify-between w-full'>

              <div>

                <h5 className='text-base font-normal text-primary '>Option sérénité</h5>

                <p className='text-sm font-normal text-primary max-w-[475px]'>Cette option inclue le contrôle de vos documents, la génération des liasses, le transfert de votre dossier auprès du greffe compétant et son suivi jusqu’à validation.</p>

              </div>

              <p className='text-right text-base font-normal text-gray mt-3 sm:mt-0 sm:mr-0 mr-2'>99,00 €</p>

            </div>



          </div>

        </div>

        <div className='py-3  px-1 sm:px-4 flex items-start justify-between mb-1'>

          <div>

            <p className='text-base font-normal text-primary'>Solde De Remise </p>

            <p className='text-base font-normal text-primary'>Utiliser Votre Solde</p>

          </div>

          <div className='text-right'>

            <p className='text-base font-normal text-primary my-1'>{cartData && cartData.data ?parseFloat(cartData.data.user_balance_discount)+(cartData.data.use_discount?0:parseFloat(cartData.data.discountAmount)):0} €</p>

            <p className='text-base font-normal text-primary my-1'>

              <Checkbox

                  checked={cartData && cartData.data ?cartData.data.use_balance:false}

                  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}

                  onChange={(e)=>handlecheckedDiscount(e.target.checked,"use_balance")}

                />

            </p>



          </div>

        </div>  

        <div className='flex items-end justify-end  flex-col  px-3 sm:px-5'>

          <div className='h-[1px] bg-gray-light mb-2 max-w-[290px] w-full'></div>

          <p className={!props.isCheckedSerenity?"hidden":"text-base font-normal text-primary my-1 flex justify-between w-72 "}>

            <span className='font-medium '>Option Sérénité &nbsp;&nbsp;&nbsp;&nbsp;</span> {/* {cartData && cartData.data ?cartData.data.subtotal:0} */} 99,00 €</p>

          <p className='text-base font-normal text-primary my-1 flex justify-between w-72'>

            <span className='font-medium'>Total HT &nbsp;&nbsp;&nbsp;&nbsp;</span> {cartData && cartData.data ?props.isCheckedSerenity?parseFloat(parseFloat(cartData.data.subtotal)+99.00).toFixed(2):cartData.data.subtotal:0} €</p>

          <p className='text-base font-normal text-primary my-1 flex justify-between w-72'><span className='font-medium'>TVA (20 %) &nbsp;&nbsp;&nbsp;&nbsp;</span> {cartData && cartData.data ?(parseFloat(cartData.data.tax)+(props.isCheckedSerenity?19.80:0)).toFixed(2):0} €</p>

          <p className='text-lg sm:text-xl font-extrabold text-primary my-2 flex justify-between w-72'><span className='font-extrabold'>Total TTC &nbsp;&nbsp;&nbsp;&nbsp;</span> {cartData && cartData.data ?parseFloat(parseFloat(cartData.data.total)+(props.isCheckedSerenity?118.80:0)).toFixed(2):0} €</p>

        </div>



        <div className='h-[1px] bg-gray-light my-2 px-5 mx-5'></div>

        <div className=' px-3 sm:px-5 mt-12'>

<h6 className='text-base font-bold text-gray'> Après réception de votre paiement, vous recevrez :</h6>


          <ul className='my-2'>

            <li className='flex gap-2 items-center text-gray'><TbPointFilled /> Une <span className="font-bold">attestation de parution officielle</span> par e-mail (à joindre à votre dossier pour le Greffe)</li>

            <li className='flex gap-2 items-center text-gray'><TbPointFilled /> Un <span className="font-bold">exemplaire papier du journal</span>, envoyé à l'adresse fournie</li>

          </ul>
          
          <h6 className='text-base font-bold text-gray'>Déléguer le paiement à un tiers</h6>
          
          <p className='text-gray'>Vous réalisez cette démarche pour le compte d'un tiers et ne souhaitez pas régler les frais vous-même ?<br/>
          Indiquez son adresse e-mail ci-dessous pour lui envoyer une invitation à régler la <span className="font-bold">facture</span> :</p>
          
          <div className="flex items-center gap-2 mt-4">
          {/* <h6 className='text-base font-medium text-gray'>Délégation de paiement du dossier à un tiers : </h6>
          <p className='text-gray'>Vous réaliser un dossier pour le compte d'un tiers et ne souhaitez pas avancer les frais ? indiquer son courriel ci-dessous pour l'inviter à payer les frais du dossier.</p> */}
            <input 
              type="email" 
              placeholder="📧 Exemple : client@gmail.com" 
              className="flex-1 border border-gray-300 rounded px-3 py-2 text-sm"
              value={delegateEmail}
              onChange={(e) => setDelegateEmail(e.target.value)}
            />
            <button 
              className="w-48 h-12 m-4 rounded-md bg-thColor font-extrabold text-base text-white transition-all duration-300 hover:bg-secondary hover:btnShadow"
              onClick={() => {
                if (delegateEmail && /\S+@\S+\.\S+/.test(delegateEmail)) {
                 //console.log('Sending invitation to:', delegateEmail);
                  HandleDelegateEmail();
                 //setDelegateEmail('');
                }
              }}
            >
            ➡️ Inviter le tiers à régler la facture
            </button>
          </div>

          <FormGroup className='mt-6 '>

            <FormControlLabel style={{  sm:`height: "25px"` }} className='text-gray font-semibold' required control={<Checkbox name='general_conditions_sale' checked={props.is_general_conditions_sale} onChange={(e)=>props.setis_general_conditions_sale(e.target.checked)} />} label="Je reconnais avoir pris connaissance des Conditions Générales de Vente." />

            <p style={{color:"red",paddingBottom:"10px",fontSize:"14px"}} >{props.general_conditions_sale_msg}</p>



            <FormControlLabel style={{  sm:`height: "25px"` }} className='text-gray font-semibold sm:py-0'  control={<Checkbox  className='sm:py-0' />} label="Je souhaite recevoir des offres commerciales de BusyPlace." />

          </FormGroup>

        </div>

        {/* <div className='flex justify-end  my-12 px-5'>

          <button className='mt-5 text-center text-lg 3xl:text-xl font-extrabold outline-thColor text-white transition-all duration-300 border border-thColor w-full py-2 rounded-[10px] px-4 max-w-[180px] bg-thColor

        hover:bg-secondary hover:text-white hover:border-secondary'>

            Paiement

          </button>

        </div> */}

   



    </>

  )

}
