import React, { useState, useEffect } from "react";
import { authHeader } from "../_helpers";
import axios from "axios";
import moment from "moment";
import Sidebar from "./component/Sidebar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { Box, Button, TextField, Typography, Collapse, Dialog, DialogActions, DialogContent, DialogTitle,Select,MenuItem } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { alertActions } from "../_actions";
import ToastMessageComp from "../component/ToastMessageComp";
import PageLoaderComp from "../component/pageLoaderComp";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    fontWeight: 600,
    borderBottom: '2px solid rgba(224, 224, 224, 1)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const ActionButton = styled(Button)({
  textTransform: 'none',
  padding: '4px 12px',
  fontSize: '14px',
  borderRadius: '4px',
  '&.display': {
    color: '#4CAF50',
    border: '1px solid #4CAF50',
  },
  '&.download': {
    color: '#FF9800',
    border: '1px solid #FF9800',
    marginLeft: '8px',
  }
});

export default function Formalities() {
  const currentAdmin = useSelector((state) => state.administrator.admin);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const [currentFiles, setCurrentFiles] = useState([]);

  const [openDocModal, setOpenDocModal] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [docMessage, setDocMessage] = useState("");
  
  const [rows, setRows] = useState([]);

  useEffect(() => {getAllFacilities();}, [startDate, endDate]);

  async function getAllFacilities() {
    setPageLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}api/admin/formalities`, {
        params: {startDate,endDate}
      });
      setRows(response.data.data);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.error(error);
    }
  }
  const handleResetSearch = () => {
    setStartDate('');
    setEndDate('');
    //getAllFacilities();
  };
  const handleRowClick = (rowId, formfile) => {
    setExpandedRow(expandedRow === rowId ? null : rowId);
    if (!formfile) {setCurrentFiles([]);return;}
    setCurrentFiles(formfile);
  };
  const documentsList = currentFiles.length>0?currentFiles : [];
  const handleDownload= (url) => {
      const pdfUrl = "https://busyplace.s3.eu-west-3.amazonaws.com/assets/uploads/"+url;
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = pdfUrl;
      link.download = url; // File name for the download
      link.click();
    };
  const handleReceptionClick = async(type, id,message) => {
    try {
        const response = await axios.post(
            `${process.env.REACT_APP_API_URL}api/admin/formalities/send`, 
            {
                type: type,
                id: id,
                message:message,
            },
            { headers: authHeader() }
        );
        if (response.status === 200) {
          dispatch(alertActions.success(response.data.msg));
          getAllFacilities();
        }
    } catch (error) 
    {
        console.error('Error sending reception data:', error);
    }
  };
  const handleDocSubmit = async () => {
    handleReceptionClick('missingdoc', selectedRowId,docMessage);
    handleDocModalClose();
    
  };

  const handleDocModalOpen = (rowId) => {
    setSelectedRowId(rowId);
    setOpenDocModal(true);
  };

  const handleDocModalClose = () => {
    setOpenDocModal(false);
    setDocMessage("");
    setSelectedRowId(null);
  };
  const handleStatusChange = async (e, id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/admin/formalities/status`,
        {
          id: id,
          status: e.target.value,
        },
        { headers: authHeader() }
      );
      const updatedDocs = documentsList.map(doc => {
        if (doc.id === id) {
          return { ...doc, status: e.target.value };
        }
        return doc;
      });
      setCurrentFiles(updatedDocs);
      if (response.status === 200) 
      {
        dispatch(alertActions.success(response.data.msg));
        console.log('Doc manquant message sent successfully');
      }
    } 
    catch (error) 
    {
      console.error('Error sending doc manquant message:', error);
    }
    
  };
  
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">Tableau de bord</Link>,
    <Typography key="2">Formalities</Typography>,
  ];
  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  }
  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}
      {pageLoading ? <PageLoaderComp /> : ""}
      <Dialog open={openDocModal} onClose={handleDocModalClose}>
        <DialogTitle>Doc manquant</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Message"
            fullWidth
            multiline
            rows={4}
            value={docMessage}
            onChange={(e) => setDocMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDocModalClose}>Annuler</Button>
          <Button onClick={handleDocSubmit} variant="contained" color="primary">
            Envoyer
          </Button>
        </DialogActions>
      </Dialog>
      <div className="w-full h-full bg-white p-8">
        <Typography variant="h4" component="h4" fontWeight={500} color="#1CBE93" mb={3}>
        Formalities
        </Typography>

        <Stack spacing={2} mb={3}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>

        <div className="flex gap-4 mb-6">
          <TextField
            label="À partir de la de"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            style={{ width: '200px' }}
          />
          <TextField
            label="À la date du"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            style={{ width: '200px' }}
          />
          {/* <Button 
            variant="contained" 
            onClick={getAllFacilities}
            style={{ 
              backgroundColor: '#1976d2',
              textTransform: 'none',
              minWidth: '120px'
            }}
          >
            Rechercher
          </Button> */}
          <Button 
            variant="contained" 
            onClick={handleResetSearch}
            style={{ 
              backgroundColor: '#808080',
              textTransform: 'none',
              minWidth: '120px'
            }}
          >clair</Button>
        </div>
        <TableContainer component={Paper} elevation={0}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>N° de Dossier</StyledTableCell>
                <StyledTableCell>Type dossier</StyledTableCell>
                <StyledTableCell>Nom dossier</StyledTableCell>
                <StyledTableCell>Nom de client</StyledTableCell>
                <StyledTableCell>Date de création</StyledTableCell>
                <StyledTableCell>Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                
                <React.Fragment key={row.id}>
                  <StyledTableRow>
                    <StyledTableCell>{row.id}</StyledTableCell>
                    <StyledTableCell>{
                              row.formtype === 'sarl-eurl' ? 'Création' :
                              row.formtype === 'SAS/SASU' ? 'Création' :
                              row.formtype === 'sci' ? 'Création' :
                              row.formtype === 'modification' ? 'Modification' :
                              row.formtype === 'dissolution' ? 'Dissolution' :
                              row.formtype === 'formalities' ? 'Approbation des comptes' : 'Création'
                            } </StyledTableCell>
                    <StyledTableCell style={{ color: '#0066CC' }}>{row.entreprisename || '-'}</StyledTableCell>
                    <StyledTableCell style={{ color: '#0066CC' }}>{row?.firstname?row.firstname:''+' '+row?.lastname?row.lastname:''}</StyledTableCell>
                    <StyledTableCell>{moment(row.created_at).format('DD MMMM YYYY')}</StyledTableCell>
                    <StyledTableCell>
                      <div 
                        style={{
                          border: '1px solid #4CAF50',
                          color: '#4CAF50',
                          borderRadius: '4px',
                          padding: '6px 12px',
                          display: 'inline-block',
                          fontSize: '14px',
                          cursor: 'pointer'
                        }}
                        onClick={() => handleRowClick(row.id,row.docs)}
                      >
                        {row.status === 2 ? 'Commande en attente':
                        row.status===3?'Document manquant':
                        row.status === 4 ? 'Commande terminée' :'En cours'
                        }
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                      <Collapse in={expandedRow === row.id} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 2 }}>
                          <Table size="small" aria-label="documents">
                            <TableBody>
                            <TableRow style={{ borderBottom: '1px solid #0066CC'}}>
                                  <TableCell style={{ width: '50%', border: 'none' }}>
                                    <Typography style={{ color: '#0066CC' }}>
                                    Les documents relatifs à votre immatriculation
                                    </Typography>
                                  </TableCell>
                                  <TableCell style={{ border: 'none' }}>
                                    {/* //1976d2 FF9800 */}
                                  <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleReceptionClick('controle', row.id,'message')}
                                        style={{
                                        marginRight: '8px',
                                        textTransform: 'none',
                                        borderColor: '#1976d2',
                                        color: '#1976d2'
                                        }}
                                    >Réception</Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleReceptionClick('send', row.id,'message')}
                                        style={{
                                        marginRight: '8px',
                                        textTransform: 'none',
                                        borderColor: '#FF9800',
                                        color: '#FF9800'
                                        }}
                                    >Envoi</Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleReceptionClick('Validet', row.id,'message')}
                                        style={{
                                        marginRight: '8px',
                                        textTransform: 'none',
                                        borderColor: '#4CAF50',
                                        color: '#4CAF50'
                                        }}
                                    >Validation</Button>
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={() => handleDocModalOpen(row.id)}
                                        style={{
                                        marginRight: '8px',
                                        textTransform: 'none',
                                        borderColor: '#1976d2',
                                        color: '#1976d2'
                                        }}
                                    >Doc manquant</Button>
                                  </TableCell>
                                </TableRow>

                                
                              {documentsList.map((doc, index) => (
                                <TableRow key={index}>
                                  <TableCell style={{ width: '50%', border: 'none' }}>
                                    <Typography style={{ color: '#0066CC' }}>
                                    {doc.file_title}
                                    </Typography>
                                  </TableCell>
                                  <TableCell style={{ border: 'none' }}>
                                    <Select  key={doc.id} className="status-select ml-2" value={doc.status}
                                      onChange={(e) => handleStatusChange(e, doc.id)}
                                    >
                                      <MenuItem value="0">En attente</MenuItem>
                                      <MenuItem value="2">valider</MenuItem>
                                      <MenuItem value="1">refuser</MenuItem>
                                    </Select>

                                    <ActionButton className="display ml-3"  onClick={() => handleDownload(JSON.parse(doc.file)[0].filename)}>
                                        Afficher
                                    </ActionButton>
                                    <ActionButton className="download" onClick={() => handleDownload(JSON.parse(doc.file)[0].filename)}>
                                      {/* {doc.file[0]} */}
                                        Télécharger
                                    </ActionButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}