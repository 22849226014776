import React, { useState, useEffect, useMemo } from "react";
import { authHeader } from "../_helpers";
import axios from "axios";
import moment from "moment";
import Sidebar from "./component/Sidebar";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { Box, Button, TextField, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { alertActions } from "../_actions";
import ToastMessageComp from "../component/ToastMessageComp";
import PageLoaderComp from "../component/pageLoaderComp";

export default function Flaterate() {
  const currentAdmin = useSelector((state) => state.administrator.admin);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pageLoading, setPageLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [columns, setColumns] = useState([
    { id: "appendix", label: "N° d’Annexe", minWidth: 100 },
    { id: "sa", label: "SA", minWidth: 90 },
    { id: "sas", label: "SAS", minWidth: 90 },
    { id: "sasu", label: "SASU", minWidth: 90 },
    { id: "snc", label: "SNC", minWidth: 90 },
    { id: "sarl", label: "SARL", minWidth: 90 },
    { id: "eurl", label: "EURL", minWidth: 90 },
    { id: "sc", label: "SC", minWidth: 90 },
    { id: "sci", label: "SCI", minWidth: 90 },
    {
      id: "changement_gerant",
      label: "Changement Gérant / Président / Nomination",
      minWidth: 90,
    },
    {
      id: "transfert_siege_meme_departement",
      label: "Transfert de siège social dans le département",
      minWidth: 90,
    },
    {
      id: "transfert_siege_hors_departement_depart",
      label: "Transfert de siège social hors département (départ)",
      minWidth: 90,
    },
    {
      id: "transfert_siege_hors_departement_arrivee",
      label: "Transfert de siège social hors département (arrivée)",
      minWidth: 90,
    },
    {
      id: "changement_denomination",
      label: "Changement de dénomination",
      minWidth: 90,
    },
    {
      id: "changement_objet_social",
      label: `Modification d'objet social`,
      minWidth: 90,
    },
    {
      id: "modification_capital_social",
      label: "Avis de modification de capital",
      minWidth: 90,
    },
    {
      id: "transformation_sarl_en_sas",
      label: "Transformation d’une SARL en SAS",
      minWidth: 90,
    },
    {
      id: "transformation_sas_en_sarl",
      label: `Transformation d’une SAS en SARL`,
      minWidth: 90,
    },
    {
      id: "cession_parts_sociales",
      label: `Cession de parts sociales`,
      minWidth: 90,
    },
    {
      id: "transformation_sa_en_sas",
      label: "Transformation d’une SA en SAS",
      minWidth: 90,
    },
    { id: "dissolution", label: "Dissolution", minWidth: 90 },
    { id: "radiation", label: "Radiation", minWidth: 90 },
    { id: "dissolution_radiation", label: "Disso+Rad", minWidth: 90 },
    { id: "price_character", label: "Prix au caractère", minWidth: 90 },
  ]);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    getAllFlaterate();
  }, []);
  async function getAllFlaterate() {
    setPageLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/admin/flatrates`
      );

      setRows(response.data.data);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
      console.error(error);
    }
  }
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
      color: "#000",
    },
  }));

  const handleUpdate = async () => {
    setSuccessful(false);

    let formData = {
      data: rows,
    };
    // console.log("formData",formData)
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}api/admin/flatrates/${rows[0].id}`,
        formData,
        authHeader()
      );

      setSuccessful(true);
      if (response.data.sucess === true) {
        dispatch(alertActions.success(response.data.msg));
        setRows(response.data.data);
      } else {
        dispatch(alertActions.error(response.data.msg));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleChange = async (e, row, column) => {
    const index = rows.findIndex((x) => x.id === row.id);
    if (index > -1) {
      const newState = [...rows];
      newState[index][column.id] = e.target.value;
      setRows(newState);
    }
  };
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Tableau de bord
    </Link>,

    <Typography key="3">Tarif forfaitaire</Typography>,
  ];
  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  }
  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}
      {pageLoading ? <PageLoaderComp /> : ""}

      <div className=" w-full h-full bg-white p-8">
        <Typography
          variant="h4"
          component="h4"
          fontWeight={500}
          color={"#1CBE93"}
        >
          Tarif forfaitaire
        </Typography>
        <Stack spacing={2} mb={3}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <Typography
          variant="h6"
          component="h6"
          fontWeight={400}
          mb={1}
          color={"#27295B"}
        >
          Gérer Les Tarifs
        </Typography>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        return (
                          <TableCell key={column.id}>
                            <TextField
                              variant="outlined"
                              name={column.id}
                              value={row[column.id]}
                              onChange={(e) => handleChange(e, row, column)}
                              size="small"
                            />
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mx: 2, textAlign: "center" }}>
            <Button
              variant="contained"
              className="bg-secondary btnShadow w-1/2 mx-auto  my-4 font-bold text-sm text-white text-center py-3 px-2 rounded-lg hover:bg-primary transition-all duration-300"
              onClick={handleUpdate}
            >
              Update
            </Button>
          </Box>
        </Paper>
      </div>
    </>
  );
}
