import { authHeader } from '../_helpers';
import axios from 'axios';
export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    update,
    addAddress,
    forgot_password,
    delete: _delete
};

async function login(userdata) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email:userdata.email,password: userdata.password })
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/login`, requestOptions);
    const user = await handleResponse(response);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    if(user.sucess == true)
    {
        //let accountTypesArray = user.data.accounttype.split(',');
       // console.log('accountTypesArray',accountTypesArray);
        if(user.data.accounttype!==null && user.data.accounttype.split(',').includes("professional"))
        {
            user.sucess = false;
            user.msg =`ce détail d'utilisateur ne permet pas de se connecter pour un utilisateur normal`;
        }
        else
        {
            localStorage.setItem('user', JSON.stringify(user));
        }
        
    }
    if(userdata.remember_password === true){
        localStorage.setItem('remember_email', userdata.email);
        localStorage.setItem('remember_password', userdata.password);
        localStorage.setItem('is_remember', userdata.remember_password);
    }else{
        localStorage.removeItem('remember_email');
        localStorage.removeItem('remember_password');
        localStorage.removeItem('is_remember');
    }
    console.log('user-dfdfgdfgdfg',user);
    return user;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('announceCurrentUrl');
}

async function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`/users`, requestOptions);
    return handleResponse(response);
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    const response = await fetch(`/users/${id}`, requestOptions);
    return handleResponse(response);
}

async function addAddress(formData) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData)
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/user_address`, requestOptions);    
    const get_user_address = await handleResponse(response);
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    if(get_user_address.sucess === true){
        let userData = localStorage.getItem('user');
        let userDataParse = JSON.parse(userData);
        userDataParse.user_address = get_user_address.user_address;
        localStorage.setItem('user', JSON.stringify(userDataParse));
    }
   
    return get_user_address;
}
async function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/register`, requestOptions);
    
    if(user.signup_type === "google" || user.signup_type === "facebook"  ){
        const user = await handleResponse(response);
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        if(user.sucess === true){
            localStorage.setItem('user', JSON.stringify(user));
        }
        return user;
    }else{
        return handleResponse(response);      
    }    
}

async function update(user) {
    delete user["email"];
    let formData = new FormData();
    // console.log("handleSubmit user update",user)
    Object.keys(user).forEach(fieldName => {
        if(fieldName === "villeArr"){
            formData.append(fieldName, JSON.stringify(user[fieldName]));
        }else{
            formData.append(fieldName, user[fieldName]);
        }
        
    });

     /* const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body:formData,   
    };

   const response = await fetch(process.env.REACT_APP_API_URL+`api/user/${user.id}`, requestOptions);    
    const user_data = await handleResponse(response); */
    const response = await axios.put(process.env.REACT_APP_API_URL+`api/user/${user.id}`, formData,authHeader());
    
    const user_data = response.data;
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    
    if(localStorage.getItem('user')){
        let getData = JSON.parse(localStorage.getItem('user'));
        getData.data = user_data.data;
        localStorage.setItem('user', JSON.stringify(getData));
    }else{
        localStorage.setItem('user', JSON.stringify(user_data));
    }
    return user_data;
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    const response = await fetch(`/users/${id}`, requestOptions);
    return handleResponse(response);
}

async function forgot_password(email) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email })
    };

    const response = await fetch(process.env.REACT_APP_API_URL+`api/forgotpassword`, requestOptions);
    const user = await handleResponse(response);    
    return user;
}

function handleResponse(response) {

    return response.text().then(text => {
        const data = text && JSON.parse(text);
        
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                logout();
                // eslint-disable-next-line no-restricted-globals
                location.reload(true);
            }

            const error = (data && data.msg) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}