import React from "react";
import { NavLink } from "react-router-dom";
import { FaRegUserCircle } from "react-icons/fa";
import {
  AiOutlineFileDone,
  AiOutlineFileProtect,
  AiOutlineFileSync,
} from "react-icons/ai";

import { RxDashboard } from "react-icons/rx";
import { TbFileInvoice } from "react-icons/tb";
import { BsFileEarmarkRichtext } from "react-icons/bs";
export default function Sidebar({ hendlemenuOpen, setMenuOpen, menuOpen }) {
  return (
    <div className="max-w-[100%] w-full bg-white rounded-e-[20px] sideShadow adminSidebar">
      {/* style={{height:'calc(100vh - 92px)'}}  */}

      <div className="xl:pt-10 pt-4">
        <ul>
          <NavLink
            onClick={() => setMenuOpen(true)}
            to="/admin/home"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <RxDashboard size={28} />
            </span>

            <span className="text-base font-medium">Tableau de bord</span>
          </NavLink>

          <NavLink
           onClick={() => setMenuOpen(true)}
            to="/admin/clients"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light font-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <FaRegUserCircle size={28} />{" "}
            </span>
            <span className="text-base font-medium">Gestion des clients</span>
          </NavLink>
          <NavLink
           onClick={() => setMenuOpen(true)}
            to="/admin/messages"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light font-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <AiOutlineFileSync size={28} />{" "}
            </span>
            <span className="text-base font-medium">Messagerie</span>
          </NavLink>
          <NavLink
           onClick={() => setMenuOpen(true)}
            to="/admin/claims"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light font-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <FaRegUserCircle size={28} />{" "}
            </span>
            <span className="text-base font-medium">
              Fiches professionnelles
            </span>
          </NavLink>
          <NavLink
           onClick={() => setMenuOpen(true)}
            to="/admin/journal"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <AiOutlineFileDone size={32} />
            </span>
            <span className="text-base font-medium">Gestion des journaux </span>
          </NavLink>
          <NavLink
           onClick={() => setMenuOpen(true)}
            to="/admin/quote"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <AiOutlineFileSync size={30} />
            </span>
            <span className="text-base font-medium">
              Annonces en attente (devis){" "}
            </span>
          </NavLink>

          <NavLink
           onClick={() => setMenuOpen(true)}
            to="/admin/devis"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <AiOutlineFileSync size={30} />
            </span>
            <span className="text-base font-medium">
              Gestion des commandes{" "}
            </span>
          </NavLink>

          <NavLink
           onClick={() => setMenuOpen(true)}
            to="/admin/formalities"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <AiOutlineFileSync size={30} />
            </span>
            <span className="text-base font-medium">Gestion des Formalities</span>
          </NavLink>

          <NavLink
           onClick={() => setMenuOpen(true)}
            to="/admin/invoice"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <TbFileInvoice size={30} />
            </span>
            <span className="text-base font-medium">Gestion des factures </span>
          </NavLink>
          <NavLink
           onClick={() => setMenuOpen(true)}
            to="/admin/flaterate"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <BsFileEarmarkRichtext size={30} />
            </span>
            <span className="text-base font-medium">Tarif forfaitaire</span>
          </NavLink>

          <NavLink
           onClick={() => setMenuOpen(true)}
            to="/admin/managerates"
            className=" flex items-center gap-x-4 py-4 mr-4 rounded-e-full pl-7 text-black-light"
          >
            <span className="w-9 h-9 flex justify-center items-center">
              <AiOutlineFileProtect size={30} />
            </span>
            <span className="text-base font-medium">Gérer les tarifs</span>
          </NavLink>
        </ul>
      </div>
    </div>
  );
}
