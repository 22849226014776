import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsCheckCircleFill } from 'react-icons/bs';
import moment from 'moment';

const PaymentSuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { orderData } = location.state || {};

  if (!orderData) {
    navigate('/dashboard');
    return null;
  }

  return (
    <div className="min-h-screen bg-gray-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow sm:rounded-lg overflow-hidden">
          {/* Header */}
          <div className="px-4 py-5 sm:p-6 border-b border-gray-200">
            <div className="text-center">
              <BsCheckCircleFill className="mx-auto h-16 w-16 text-green-500" />
              <h3 className="mt-2 text-2xl font-semibold text-gray-900">
                Paiement réussi!
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Numéro de commande: {orderData.payment_order_id}
              </p>
            </div>
          </div>

          {/* Order Details */}
          <div className="px-4 py-5 sm:p-6">
            <div className="space-y-6">
              {/* Payment Information */}
              <div>
                <h4 className="text-lg font-medium text-gray-900">Informations de paiement</h4>
                <dl className="mt-3 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                  <div>
                    <dt className="text-sm font-medium text-gray-500">Date de paiement</dt>
                    <dd className="mt-1 text-sm text-gray-900">{orderData.date_payment}</dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-500">Numéro de facture</dt>
                    <dd className="mt-1 text-sm text-gray-900">{orderData.invoice_number}</dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-500">Montant total</dt>
                    <dd className="mt-1 text-sm text-gray-900">{orderData.payment_price}€</dd>
                  </div>
                  <div>
                    <dt className="text-sm font-medium text-gray-500">ID de transaction</dt>
                    <dd className="mt-1 text-sm text-gray-900">{orderData.payment_intent}</dd>
                  </div>
                </dl>
              </div>

              {/* Billing Information */}
              {orderData.billing_address_json_parse && (
                <div>
                  <h4 className="text-lg font-medium text-gray-900">Informations de facturation</h4>
                  <dl className="mt-3 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                    <div>
                      <dt className="text-sm font-medium text-gray-500">Nom</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {orderData.billing_address_json_parse.billing_address_nom || '-'}
                      </dd>
                    </div>
                    <div>
                      <dt className="text-sm font-medium text-gray-500">Email</dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {orderData.billing_address_json_parse.email || '-'}
                      </dd>
                    </div>
                  </dl>
                </div>
              )}
            </div>
          </div>

          {/* Actions */}
          <div className="px-4 py-5 sm:p-6 bg-gray-50 space-y-4 sm:space-y-0 sm:space-x-4 sm:flex sm:justify-end">
            <button
              onClick={() => navigate('/dashboard')}
              className="w-full sm:w-auto inline-flex justify-center items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Aller au tableau de bord
            </button>
            <button
              onClick={() => window.print()}
              className="w-full sm:w-auto inline-flex justify-center items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
            >
              Imprimer le reçu
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
