import React, { useState, useEffect, useMemo } from "react";
import axios from "axios";
import moment from "moment";
import Sidebar from "./component/Sidebar";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { Breadcrumbs, Stack, Button } from "@mui/material";
import Link from "@mui/material/Link";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";
import { BiEuro } from "react-icons/bi";
import { MdDeleteOutline, MdEditSquare } from "react-icons/md";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { alertActions } from "../_actions";
import ToastMessageComp from "../component/ToastMessageComp";
import TextField from "@mui/material/TextField";
import DialogContentText from "@mui/material/DialogContentText";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import SendIcon from "@mui/icons-material/Send";
import SaveIcon from "@mui/icons-material/Save";
import LoadingButton from "@mui/lab/LoadingButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { frFR } from "@mui/x-date-pickers/locales";
import dayjs from "dayjs";
import "dayjs/locale/fr.js";
import Anchor from "../_helpers/anchor";
import PageLoaderComp from "../component/pageLoaderComp";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const breadcrumbs = [
  <Link underline="hover" key="1" color="inherit" href="/">
    Tableau de bord
  </Link>,

  <Typography key="3">Gestion des factures</Typography>,
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#fff",
      color: "#797979",
      fontSize: "16px",
      fontWeight: "400",
    },
  }));

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "normal" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  let totalRows = props.totalRows;
  const handleSearch = (event) => {
    if(event.target.value.length>0){
      let searchVal = event.target.value;
      const filteredRows = totalRows.filter((row) => {
        let socitename = (row.socitename)?(row.socitename).toLowerCase():"";
        let invoice_number = (row.invoice_number)?(row.invoice_number).toLowerCase():"";
        let payment_order_id = (row.payment_order_id)?(row.payment_order_id).toLowerCase():"";
        
        return invoice_number.includes(searchVal.toLowerCase()) || payment_order_id.includes(searchVal.toLowerCase()) || socitename.includes(searchVal.toLowerCase())
        
        /* let telephone = (row.telephone).toLowerCase();
        let ville = (row.ville).toLowerCase(); */
       
      });
      props.setRows(filteredRows);
    }else{
      props.setRows(totalRows)

    }
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%", marginTop: "15px" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            inputProps={{ "aria-label": "search " }}
            onInput={handleSearch}
          />

          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search" disabled>
            <SearchIcon />
          </IconButton>
        </Paper>
      </Typography>

      <Tooltip title="Filter list">
        <IconButton>
          {/* <FilterListIcon /> */}
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

export default function InvoiceComp() {
  const [publicationDate, setPublicationDate] = useState("");
  const [openModel, setOpenModel] = useState(false);
  const [openModelDate, setOpenModelDate] = useState(false);
  const currentAdmin = useSelector((state) => state.administrator.admin);
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [ConfirmValue, setConfirmValue] = useState("");
  const [editFormData, setEditFormData] = useState({
    billing_address_type: "another_address",
    billing_address_denomination: "",
    billing_address_civility: "",
    billing_address_nom: "",
    billing_address_prenom: "",
    billing_address_code_postal: "",
    billing_address_address: "",
    billing_address_ville: "",
    billing_address_ville_id: "",
    billing_address_villeArr: [],
  });

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [pageLoading, setPageLoading] = useState(false);

  const [headCells, setHeadCells] = useState([
    {
      id: "NdeFacture",
      numeric: false,
      disablePadding: true,
      label: `N° de facture`,
    },
    {
      id: "DENOMINATION",
      numeric: true,
      disablePadding: false,
      label: "DÉNOMINATION",
    },

    {
      id: "CLIENT",
      numeric: true,
      disablePadding: false,
      label: "CLIENT",
    },
    {
      id: "FormType",
      numeric: true,
      disablePadding: false,
      label: `TYPES D'ANNONCE`,
    },
    {
      id: "Montant",
      numeric: true,
      disablePadding: false,
      label: "Montant",
    },
    {
      id: "DateCreation",
      numeric: true,
      disablePadding: false,
      label: `Date de publication`,
    },
    {
      id: "Actions",
      numeric: true,
      disablePadding: false,
      label: "Actions",
    },
  ]);
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowData, setRowData] = useState(null);

  const visibleRows = useMemo(
    () => stableSort(rows, getComparator(order, orderBy)),
    // .slice(
    //   page * rowsPerPage,
    //   page * rowsPerPage
    //   )
    [order, orderBy, page, rowsPerPage, rows]
  );
  useEffect(() => {
    // console.log("visibleRows:", visibleRows);
    // console.log("page:", page);
  }, [visibleRows, page]);

  useEffect(() => {
    getAllInvoice(page);
  }, []);

  async function getAllInvoice(pageNumber) {
    try {
      setPageLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/announce/announce?page=${
          pageNumber + 1
        }`
      );

      setPageLoading(false);
      // setPage(pageNumber - 1);
      setRows(response.data.data);
      setTotalRows(response.data.data)
      setTotalRecords(response.data.total);
    } catch (error) {
      setPageLoading(false);
      console.error(error);
    }
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getAllInvoice(newPage);
    // console.log("newPage:", newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRecords) : 0;

  const handleCancel = () => {
    setConfirmOpen(false);
  };

  const handleOk = async (e, deleteIdGet) => {
    setConfirmOpen(false);
    // console.log("deleteIdGet",deleteIdGet)
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}api/announce/announce/${deleteIdGet}`
      );
      if (response.data.sucess === true) {
        dispatch(alertActions.success(response.data.msg));
        // setRows(rows.filter(row => row.id !== deleteIdGet));
        getAllInvoice(page);
        // setRows(response.data.data);
      } else {
        dispatch(alertActions.error(response.data.msg));
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleClick = (e, rowData) => {
    handleClose1(rowData.id);
    setConfirmOpen(true);
    setDeleteId(rowData.id);
  };

  const handleClickEdit = (e, rowData) => {
    handleClose1(rowData.id);
    setOpenModel(true);
    setRowData(rowData);

    if (rowData.billing_address_json != null) {
      let billing_address_json_parse = JSON.parse(rowData.billing_address_json);
      setEditFormData({
        billing_address_type: "another_address",
        billing_address_denomination:
          billing_address_json_parse.billing_address_denomination,
        billing_address_civility:
          billing_address_json_parse.billing_address_civility,
        billing_address_nom: billing_address_json_parse.billing_address_nom,
        billing_address_prenom:
          billing_address_json_parse.billing_address_prenom,
        billing_address_code_postal:
          billing_address_json_parse.billing_address_code_postal,
        billing_address_address:
          billing_address_json_parse.billing_address_address,
        billing_address_ville: billing_address_json_parse.billing_address_ville
          ? billing_address_json_parse.billing_address_ville
          : "",
        billing_address_ville_id:
          billing_address_json_parse.billing_address_ville_id
            ? billing_address_json_parse.billing_address_ville_id
            : "",
        billing_address_villeArr:
          billing_address_json_parse.billing_address_villeArr
            ? billing_address_json_parse.billing_address_villeArr
            : [],
      });
    }
    /* setConfirmOpen(true);
    setDeleteId(rowData.id); */
  };

  const handleCloseModel = () => {
    setOpenModel(false);
    setSubmitted(false);
  };
  const handleSubmitModel = async () => {
    /* console.log("editFormData",editFormData)
    console.log("rowData",rowData) */
    let billing_address_json_data = rowData.billing_address_json;
    let billing_address_json_parse = billing_address_json_data;
    if (billing_address_json_parse != null) {
      billing_address_json_parse = JSON.parse(billing_address_json_parse);
      billing_address_json_parse.billing_address_denomination =
        editFormData.billing_address_denomination;
      billing_address_json_parse.billing_address_civility =
        editFormData.billing_address_civility;
      billing_address_json_parse.billing_address_nom =
        editFormData.billing_address_nom;
      billing_address_json_parse.billing_address_prenom =
        editFormData.billing_address_prenom;
      billing_address_json_parse.billing_address_address =
        editFormData.billing_address_address;
      billing_address_json_parse.billing_address_code_postal =
        editFormData.billing_address_code_postal;
      billing_address_json_parse.billing_address_ville =
        editFormData.billing_address_ville;
      billing_address_json_parse.billing_address_villeArr =
        editFormData.billing_address_villeArr;
      billing_address_json_parse.billing_address_ville_id =
        editFormData.billing_address_ville_id;
    } else {
      billing_address_json_parse = editFormData;
    }

    setSuccessful(false);

    if (
      editFormData.billing_address_denomination &&
      editFormData.billing_address_civility &&
      editFormData.billing_address_nom &&
      editFormData.billing_address_prenom &&
      editFormData.billing_address_address &&
      editFormData.billing_address_code_postal &&
      editFormData.billing_address_ville_id
    ) {
      setSubmitted(false);
      setLoading(true);
      try {
        let sendData = {
          announce_id: rowData.id,
          editFormData: editFormData,
          billing_address_json: billing_address_json_parse,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/announce/update_invoice`,
          sendData
        );

        dispatch(alertActions.success(response.data.msg));
        setSuccessful(true);
        setOpenModel(false);
        setSubmitted(false);
        setLoading(false);
      } catch (error) {
        console.error(error);
        dispatch(
          alertActions.error("Une erreur s'est produite. Veuillez réessayer!")
        );
        setSuccessful(true);
        setSubmitted(false);
        setLoading(false);
      }
    } else {
      setSubmitted(true);
      setLoading(false);
    }
  };
  const handleChangeModel = (e) => {
    /* console.log("rowData e",e)
    console.log("rowData",rowData) */
    let billing_address_json_parse = rowData.billing_address_json;
    let { name, value } = e.target;
    setEditFormData((editFormData) => ({ ...editFormData, [name]: value }));

    if (name == "billing_address_ville_id") {
      let filterCity = editFormData.billing_address_villeArr.filter(
        (cityVal) => cityVal.id === value
      );
      if (filterCity.length > 0) {
        setEditFormData((editFormData) => ({
          ...editFormData,
          ["billing_address_ville"]: filterCity[0].city,
        }));
      }
    }

    if (name == "billing_address_code_postal") {
      const getData = setTimeout(async () => {
        setEditFormData((editFormData) => ({
          ...editFormData,
          billing_address_ville: "",
          billing_address_ville_id: "",
          billing_address_villeArr: [],
        }));
        if (value.length > 0) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`
          );
          if (response.data && response.data.data) {
            setEditFormData((editFormData) => ({
              ...editFormData,
              billing_address_ville: response.data.data.city,
              billing_address_ville_id: response.data.data.id,
              billing_address_villeArr: response.data.data.cityArr,
            }));
          }
        }
      }, 1000);
      return () => clearTimeout(getData);
    }
    // setOpenModel(true)
    /* setConfirmOpen(true);
    setDeleteId(rowData.id); */
  };

  const handleClickEditDate = (e, rowData) => {
    // console.log("rowData.date_parution",rowData.date_parution)
    setRowData(rowData);
    setPublicationDate(moment(rowData.date_parution).format("YYYY-MM-DD"));
    setOpenModelDate(true);
    handleClose1(rowData.id);
  };

  const handleCloseModelDate = () => {
    setOpenModelDate(false);
    setSubmitted(false);
  };

  const handleSubmitModelDate = async () => {
    setLoading(true);
    setSuccessful(false);
    let publishDate = moment(publicationDate).format("YYYY-MM-DD");

    if (rowData) {
      try {
        let sendData = {
          announce_id: rowData.id,
          pubishdate: publishDate,
        };
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}api/announce/changepublishdate`,
          sendData
        );
        setRows((prevItems) =>
          prevItems.map(
            (item) =>
              item.id === rowData.id
                ? {
                    ...item,
                    date_parution: publishDate,
                    attestation: response.data.data.attestation,
                    votre_pdf: response.data.data.votre_pdf,
                  } // Update the specific field
                : item // Return the unchanged item
          )
        );
        dispatch(alertActions.success(response.data.msg));
        setSuccessful(true);
        setOpenModelDate(false);
        setSubmitted(false);
        setLoading(false);
        setOpenModelDate(false);
        handleClose1(rowData.id);
      } catch (error) {
        console.error(error);
        dispatch(
          alertActions.error("Une erreur s'est produite. Veuillez réessayer!")
        );
        setSuccessful(true);
        setSubmitted(false);
        setLoading(false);
      }
    }
  };
  const handleChangeModelDate = (e) => {
    /* let billing_address_json_parse = rowData.billing_address_json;
    let { name, value}=e.target;
    setEditFormData(editFormData => ({ ...editFormData, [name]: value }));

    if(name == "billing_address_ville_id"){
      let filterCity = editFormData.billing_address_villeArr.filter((cityVal)=>cityVal.id===value);
      if(filterCity.length>0){
        setEditFormData(editFormData => ({ ...editFormData, ["billing_address_ville"]: filterCity[0].city }));

      }
    }

    if(name == "billing_address_code_postal"){ 
      const getData = setTimeout(async() => {        
        setEditFormData(editFormData => ({ ...editFormData, "billing_address_ville": "", "billing_address_ville_id": "","billing_address_villeArr":[]  }));  
        if(value.length>0){
          const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`);          
          if( response.data &&  response.data.data){           
            setEditFormData(editFormData => ({ ...editFormData, "billing_address_ville": response.data.data.city, "billing_address_ville_id": response.data.data.id ,"billing_address_villeArr":response.data.data.cityArr })); 
          }
        }      
      }, 1000)
      return () => clearTimeout(getData);
    } */
  };
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEls, setAnchorEls] = useState({});
  const open = anchorEl;

  const handleClose1 = (id) => {
    // setAnchorEl(null);
    setAnchorEls((prev) => ({ ...prev, [id]: null }));
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClick1 = (event, id) => {
    // setAnchorEl(event.currentTarget);
    setAnchorEls((prev) => ({ ...prev, [id]: event.currentTarget }));
  };
  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  }
  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}
      {pageLoading && <PageLoaderComp />}

      <div className=" w-full h-full bg-white p-8">
        <Typography
          variant="h4"
          component="h4"
          fontWeight={500}
          color={"#1CBE93"}
        >
          Gestion des factures
        </Typography>
        <Stack spacing={2} mb={3}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar  setRows={setRows} rows={rows} setTotalRows={setTotalRows} totalRows={totalRows} />
            <TableContainer sx={{ marginTop: "15px" }}>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={totalRecords}
                  headCells={headCells}
                />
                <TableBody>
                  {visibleRows.map((row) => {
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.name}
                        sx={{ cursor: "pointer" }}
                        className={"invoiceRow_" + row.id}
                        id={"invoiceRow_" + row.id}
                      >
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          {row.payment_order_id}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          {row.votre_annonce_parse.denomination
                            ? row.votre_annonce_parse.denomination
                            : row.votre_annonce_parse.denomination_societe
                            ? row.votre_annonce_parse.denomination_societe
                            : row.socitename}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          <Link
                            className="hover:text-secondary"
                            href={`/admin/clientdetails/${row.user_id}`}
                          >
                            {row.user_name}
                          </Link>
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          {row.form_type_text}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          {row.total}
                        </TableCell>
                        <TableCell
                          className="text-base font-normal text-primary whitespace-nowrap"
                          align="left"
                        >
                          {moment(row.date_parution).format("LL")}
                        </TableCell>
                        <TableCell align="center">
                          <div key={row.id}>
                            <IconButton
                              aria-label="more"
                              aria-controls={`long-menu-${row.id}`}
                              aria-haspopup="true"
                              onClick={(e) => handleClick1(e, row.id)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id={`long-menu-${row.id}`}
                              anchorEl={anchorEls[row.id]}
                              keepMounted
                              open={Boolean(anchorEls[row.id])}
                              onClose={() => handleClose1(row.id)}
                              PaperProps={{
                                style: {
                                  maxHeight: ITEM_HEIGHT * 4.5,
                                  width: "20ch",
                                },
                              }}
                            >
                              <MenuItem
                                key={"Modifier"}
                                onClick={(e) => handleClickEdit(e, row)}
                              >
                                <MdEditSquare size={25} color="green" />{" "}
                                &nbsp;&nbsp;{"Modifier"}
                              </MenuItem>
                              {/* <MenuItem key={"voir les annonces"} onClick={() => window.open(`${process.env.REACT_APP_API_URL}uploads/pdfgenerate/invoice/${row.votre_pdf}`, '_blank')} >
                                    <VisibilityIcon color={'primary'} /> &nbsp; {"voir les annonces"}                                 
                                  </MenuItem> */}
                              <MenuItem key={"voir la facture"}>
                                <Anchor
                                  href={`${process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE}assets/uploads/pdfgenerate/invoice/${row.votre_pdf}`}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <VisibilityIcon color={"primary"} /> &nbsp;{" "}
                                  {"voir la facture"}
                                </Anchor>
                              </MenuItem>
                              {/* <MenuItem
                                        key={"date de parution"}
                                        onClick={(e) =>
                                          handleClickEditDate(e, row)
                                        }
                                      >
                                        <CalendarTodayIcon color={"primary"} />
                                        &nbsp;&nbsp;{"date de parution"}
                                      </MenuItem> */}
                              <MenuItem
                                key={"Supprimer"}
                                onClick={(e) => handleClick(e, row)}
                              >
                                <MdDeleteOutline size={25} color="red" />{" "}
                                &nbsp;&nbsp;{"Supprimer"}
                              </MenuItem>
                            </Menu>
                          </div>
                          {/*  <Stack spacing={1} direction="row"   divider={<Divider orientation="vertical" flexItem />} >
                                  
                                  <Item>
                                    <BiEuro size={25} color={row.status === "2" || row.status === 2 ?'red':'green'}/>
                                  </Item>
                                  <Item>
                                    <Link className="" href={`${process.env.REACT_APP_API_URL}uploads/pdfgenerate/invoice/${row.votre_pdf}`} target="_blank" rel="noopener"> 
                                      <VisibilityIcon color={'primary'} />
                                    </Link>
                                  </Item>
                                  <Item>
                                    <MdEditSquare size={25} color='green' onClick={(e)=>handleClickEdit(e,row)} />
                                  </Item>
                                  <Item>
                                    <MdDeleteOutline size={25} color='red' onClick={(e)=>handleClick(e,row)} />
                                  </Item>
                                </Stack> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                 {rows.length <=0 && (
                    <TableRow
                    >
                      <TableCell colSpan={6}  ><p className='text-center font-bold capitalize text-lg'>Aucune donnée!</p></TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={totalRecords}
              rowsPerPage={10}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>

      <Dialog
        sx={{ "& .MuiDialog-paper": { width: "80%", maxHeight: 435 } }}
        maxWidth="xs"
        // TransitionProps={{ onEntering: handleEntering }}
        open={ConfirmOpen}
      >
        <DialogTitle>Are you sure ?</DialogTitle>
        <DialogContent dividers>
          <p>Are you sure want to delete Invoice?</p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCancel}>
            No
          </Button>
          <Button onClick={(e) => handleOk(e, deleteId)}>Yes</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openModel} onClose={handleCloseModel} maxWidth={"lg"}>
        <DialogTitle>Adresse de facturation </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* To subscribe to this website, please enter your email address here. We
            will send updates occasionally. */}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_denomination"
            name="billing_address_denomination"
            value={editFormData.billing_address_denomination}
            error={
              !editFormData.billing_address_denomination && submitted
                ? true
                : false
            }
            onChange={handleChangeModel}
            label="Dénomination"
            type="text"
            fullWidth
            variant="standard"
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="billing_address_civility"
            name="billing_address_civility"
            value={editFormData.billing_address_civility}
            error={
              !editFormData.billing_address_civility && submitted ? true : false
            }
            onChange={handleChangeModel}
            label="Civilité"
            type="text"
            fullWidth
            variant="standard"
          /> */}
          <InputLabel id="Civilite">Civilité</InputLabel>
            <Select
                labelId="Civilite"
                label="Civilité"
                name="billing_address_civility"
                fullWidth
                value={editFormData.billing_address_civility}error={!editFormData.billing_address_civility && submitted ? true : false}
                onChange={handleChangeModel}
              >
              <MenuItem value={"M"}>M</MenuItem>
              <MenuItem value={"MME"}>MME</MenuItem>
            </Select>
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_nom"
            name="billing_address_nom"
            value={editFormData.billing_address_nom}
            error={
              !editFormData.billing_address_nom && submitted ? true : false
            }
            onChange={handleChangeModel}
            label="Nom"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_prenom"
            name="billing_address_prenom"
            value={editFormData.billing_address_prenom}
            error={
              !editFormData.billing_address_prenom && submitted ? true : false
            }
            onChange={handleChangeModel}
            label="Prénom"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_address"
            name="billing_address_address"
            value={editFormData.billing_address_address}
            error={
              !editFormData.billing_address_address && submitted ? true : false
            }
            onChange={handleChangeModel}
            label="Adresse"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            autoFocus
            margin="dense"
            id="billing_address_code_postal"
            name="billing_address_code_postal"
            value={editFormData.billing_address_code_postal}
            error={
              !editFormData.billing_address_code_postal && submitted
                ? true
                : false
            }
            onChange={handleChangeModel}
            label="Code postal"
            type="text"
            fullWidth
            variant="standard"
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="demo-simple-select-label">Ville</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="billing_address_ville_id"
              name="billing_address_ville_id"
              value={editFormData.billing_address_ville_id}
              error={
                !editFormData.billing_address_ville_id && submitted
                  ? true
                  : false
              }
              label="Ville"
              onChange={handleChangeModel}
            >
              {editFormData.billing_address_villeArr.map((val, key) => {
                return <MenuItem value={val.id}>{val.city}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModel}>Annuler</Button>
          {/* <Button onClick={handleSubmitModel}>Enregistrer</Button> */}
          <LoadingButton
            size="small"
            onClick={handleSubmitModel}
            startIcon={<SaveIcon />}
            loading={loading}
            loadingPosition="start"
            variant="contained"
          >
            <span>Enregistrer</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openModelDate}
        onClose={handleCloseModelDate}
        maxWidth={"lg"}
      >
        <DialogTitle>Date de parution</DialogTitle>
        <DialogContent>
          <DialogContentText>Date de parution</DialogContentText>

          <LocalizationProvider
            dateAdapter={AdapterDayjs}
            adapterLocale="fr"
            localeText={
              frFR.components.MuiLocalizationProvider.defaultProps.localeText
            }
          >
            <DatePicker
              value={dayjs(publicationDate)}
              name="date_typepublication"
              format={"DD/MM/YYYY"}
              onChange={(date) => setPublicationDate(date.$d)}
              customInput={
                <TextField
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  size="small"
                />
              }
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModelDate}>Annuler</Button>
          {/* <Button onClick={handleSubmitModel}>Enregistrer</Button> */}
          <LoadingButton
            size="small"
            onClick={handleSubmitModelDate}
            startIcon={<SaveIcon />}
            loading={loading}
            loadingPosition="start"
            variant="contained"
          >
            <span>Sauvegarder</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
